import React from 'react';
import { graphql } from 'gatsby';

import PageHero from 'src/components/content/PageHero';
import MarkdownSectionsNavigator from 'src/components/markdown/MarkdownSectionsNavigator';
import { useLocalization } from 'src/providers/LocalizationProvider';
import DataProvider from 'src/providers/DataProvider';

export default function CottonPage({ data }) {
  const {
    allFile: { nodes: heroImages },
    craft: { categories },
  } = data;

  const { page, t } = useLocalization();
  return (
    <DataProvider categories={categories}>
      <PageHero
        focusColor="brand.cotton"
        textColor="common.white"
        images={heroImages}
        text={t('hero.cotton')}
      />
      <MarkdownSectionsNavigator page={page} focusColor="brand.cotton" />
    </DataProvider>
  );
}

export const query = graphql`
  query CottonPage($locale: String) {
    allFile(
      limit: 2
      filter: { relativePath: { regex: "/hero/cotton(.*)/i" } }
    ) {
      nodes {
        childImageSharp {
          fluid(maxHeight: 280, maxWidth: 380) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    craft {
      categories(site: [$locale]) {
        title
        groupHandle
      }
    }
  }
`;
